import "@fortawesome/fontawesome-free/css/all.css";
import jQuery from "jquery";

// export for others scripts to use
window.$ = window.jQuery = $ = jQuery;

$(() => {
  run();
});

const run = () => {
  const elementList = [].slice.call(document.querySelectorAll(".splide"));
  elementList.map(function(element) {
    return new bootstrap.CarouselBI(element);
  });

  // Soluzione bug #101
  $(".skip-to").bind("click", function(event) {
    const element_id = $(this).attr("href");
    window.location.hash = element_id;
    $(element_id).focus();
  });
};
